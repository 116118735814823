import type { Countdown } from "@ft9/countdown";
import type { QBus } from "@otto-ec/global-resources/event-q-bus";

export class PapercardCinema {
	constructor(
		private readonly countdown: Countdown,
		private readonly eventQBus: QBus,
	) {}

	/*                                                                        */
	/*                                                                              */
	init() {
		/*                                                                                             */
		this.eventQBus.on("ft9.benefit.init", () => {
			const container = document.getElementById("reptile-search-result");
			if (container) {
				this.countdown.initPapercardCountdown(container, true);
			}
		});

		/*                                                                                                   */
		this.eventQBus.on("ftfind.tilelist.restored", () => {
			const container = document.getElementById("reptile-search-result");
			if (container) {
				this.countdown.initPapercardCountdown(container, true);
			}
		});

		this.countdown.initPapercardCountdown(document.body);
	}
}
